import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import throttle from "lodash/throttle";

const DsaRoll = ({ data }) => {
  const [postsToShowDsa, setpostsToShowDsa] = useState(() => {
    if (typeof window !== `undefined`) {
      return window.postsToShowDsa || 6
    }
    return 6
  })
  const [showingMore, setShowingMore] = useState(postsToShowDsa > 6)

  const throttledUpdate = throttle(update, 200)

  function update() {
    const distanceToBottom =
      document.documentElement.scrollHeight -
      (window.scrollY + window.innerHeight)

    if (showingMore && distanceToBottom < 225) {
      setpostsToShowDsa(postsToShowDsa + 18)
    }

    throttledUpdate.ticking = false
  }

  const handleScroll = () => {
    if (!throttledUpdate.ticking) {
      throttledUpdate.ticking = true
      requestAnimationFrame(() => update())
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.postsToShowDsa = postsToShowDsa
    }
  })

  const posts = data.allMarkdownRemark.edges

  return (
    <div className="px-3">
      <div className="grid gap-8 xl:gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {posts.slice(0, postsToShowDsa).map(({ node: post }) => (
          <Link to={post.frontmatter.path} className="h-full" key={post.id}>
            <div className="flex flex-col h-full shadow-md hover:shadow-xl bg-white transition duration-150 rounded-2xl border">
              {post.frontmatter.featuredimage && (
                <div className="flex mt-3 justify-center">
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      paddingTop: "56.25%",
                    }}
                  >
                    <img
                      className="object-cover pointer-events-none absolute top-0"
                      src={require("../../static/" +
                        post.frontmatter.featuredimage)}
                      alt={post.frontmatter.title}
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col tracking-wider justify-between p-5">
                <div className="text-lg mt-2 xl:text-xl 2xl:text-2xl leading-6 text-gray-900">
                  {post.frontmatter.seotitle}
                </div>
                <div className="text-sm 2xl:text-base mt-3 text-gray-600">
                  <time dateTime="2020-03-16">{post.frontmatter.date}</time>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {postsToShowDsa < posts.length && !showingMore && (
        <div className="flex justify-center mt-12 mb-8">
          <button
            id="loadMoreBlog"
            className="px-5 py-3 bg-white shadow rounded border border-new-purple text-new-purple hover:bg-new-purple text-white hover:text-white transition duration-150"
            onClick={() => {
              setpostsToShowDsa(prevpostsToShowDsa => prevpostsToShowDsa + 18)
              setShowingMore(true)
            }}
          >
            <h3 className="flex mx-auto tracking-wider text-lg 2xl:text-xl">
              Load More Blogs
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="feather feather-arrow-down ml-2"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <polyline points="19 12 12 19 5 12"></polyline>
              </svg>
            </h3>
          </button>
        </div>
      )}
    </div>
  )
}

DsaRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query DsaRollQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: { visibility: { eq: true }, category: { eq: "dsa" } }
          }
          sort: { order: DESC, fields: frontmatter___date }
        ) {
          edges {
            node {
              id
              frontmatter {
                path
                date(formatString: "DD MMMM YYYY")
                category
                title
                seotitle
                featuredimage
                visibility
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <DsaRoll data={data} count={count} />}
  />
)
